* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: JetBrains Mono,Consolas, 'Courier New', monospace;
  /* font-family: "Times New Roman", Times, serif; */
}

width ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.shadow {
  box-shadow: 0px 0px 5px 0px;
}
